@use '@angular/material' as mat;
@import "styles/variables";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$app-typography: mat.define-typography-config($font-family: 'Roboto, monospace',
  $headline: mat.define-typography-level(34px, 41px, 400),
);

@include mat.core($app-typography);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$app-theme: mat.define-light-theme((color: (primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

/*********************/
