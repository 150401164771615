@mixin expand ($width: 100%, $height: 100%, $position: absolute, $top: 0, $left: 0) {
  width: $width;
  height: $height;
  position: $position;
  top: $top;
  left: $left;
}

@mixin visibility ($opacity: 1, $pointer-events: auto) {
  opacity: $opacity;
  pointer-events: $pointer-events;
}

@mixin centralizer ($width: auto, $margin: 0 auto) {
  width: $width;
  margin: $margin;
}

@mixin display-flex ($flex-direction: row, $justify-content: center, $align-items: center) {
  display: flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
}
