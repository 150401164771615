.mat-form-field-custom-date {
  & mat-datepicker-toggle {
    position: absolute;
    top: 0;
    left: 0;
  }

  &>div {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  &.mat-form-field-should-float {
    mat-label {
      padding-left: 16px !important;
    }
  }

  .mat-form-field-outline-start {
    border-radius: 50px 0 0 50px !important;
    min-width: 24px !important;
  }

  .mat-form-field-outline-end {
    border-radius: 0 50px 50px 0 !important;
  }

  .mat-form-field-infix {
    padding-left: 8px !important;
  }

  mat-label {
    margin-left: 32px;
    padding-left: 16px !important;
  }

  input {
    padding-left: 40px !important;
  }
}