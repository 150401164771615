@use '@angular/material' as mat;
@import '../palette';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette($closecare-blue);
$app-accent: mat.define-palette($closecare-orange);
$app-warn: mat.define-palette(mat.$red-palette);

$color-primary: mat.get-color-from-palette($app-primary);
$color-accent: mat.get-color-from-palette($app-accent);
$color-warn: #C50F0F;
$color-gradient-second: #9025FC;

$color-font-primary: #171F24;
$color-light-text: #6f6f6f;
$color-light-grey: #AAB2B7;
$color-dark-grey: #5B5B5B;
$color-almost-white: #dedede;

$color-success-green: #007B1B;
$color-link-blue: #03318C;
$color-ciano: #00B5CE;
$color-petro-blue: #005281;

$shadow: 0 10px 20px #dddddd;

// Sizes
$size-sm: 576px;
$size-md: 768px;
$size-lg: 992px;
$size-xl: 1200px;

//Timers
$timer-standard: 400ms;
$timer-faster: $timer-standard * 0.25;
$timer-fast: $timer-standard * 0.5;
$timer-slow: $timer-standard * 1.5;
$timer-slower: $timer-standard * 2;
